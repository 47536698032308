<template lang="html">
  <div class="container-fluid contain">
    <div >
      <div>
        <img src="@/assets/images/icon_arrow_left.png" style="width: 18px; margin-left: 20px;" @click="$router.go(-1)"/>
        <div class="login-title" style="margin-top: 32px; margin-bottom: 30px; text-align: left; font-size: 30px; margin-left: 20px;">DELIVER TO</div>
        <div class="field-name" style="flex-direction: column; margin: 35px 10px 0 25px">
          <div>RECIPIENT</div>
          <input class="field-area" style="width: 100%;" v-model="recipient">
        </div>
        <div class="field-name" style="flex-direction: column; margin: 35px 10px 0 25px">
          <div>PHONE NUMBER</div>
          <the-mask class="field-area" style="width: 100%;" mask="(###) ###-####" v-model="number"></the-mask>
        </div>
        <div class="field-name" style="flex-direction: column; margin: 35px 10px 0 25px">
          <div>ADDRESS</div>
          <input class="field-area" style="width: 100%;" autocomplete="on" v-model="address">
          <div class="address-view" v-if="isOpen">
            <a class="address-item" v-for="(place, index) in state.google.placeResults" :key="index"
               @click="onClickAddress(index)">{{place.formatted_address}}</a>
          </div>
        </div>
        <div class="field-name" style="flex-direction: column; margin: 35px 10px 90px 25px">
          <div>UNIT</div>
          <input class="field-area" style="width: 100%;" v-model="unit">
        </div>
        <button class="btn btn-login d-flex justify-content-center align-items-center" style="width: 99%" type="button" @click="onPressContinue">
          <div class="loader" v-if="state.isLoading"></div>
          <div v-else>CONTINUE</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';
  import {isEmpty, get} from 'lodash';
  import {json} from 'overmind';
  export default {
    setup(props, {root}) {
      const data = reactive({
        recipient: '',
        number: '',
        address: '',
        unit: '',
        isOpen: false,
        site: {},
        user: {},
        siteId: null,
      });
      const checkValid = () => {
        if (isEmpty(data.recipient)) {
          return 'Please input Recipient!'
        }
        if (isEmpty(data.number)) {
          return 'Please input Phone Number!'
        }
        if (data.number.replace(/[^A-Z0-9]/ig, "")?.length !== 10) {
          return 'Please input valid Phone Number!'
        }
        if (isEmpty(data.site?.name)) {
          return 'Please choose Delivery Address!'
        }
        return 'valid';
      };
      const onPressContinue = async () => {
        if (checkValid() !== 'valid') {
          root.actions.alert.showError({message: checkValid()});
        } else {
          const params = {
            addressNumber: data.number.replace(/[^A-Z0-9]/ig, ""),
            addressName: data.recipient,
            siteName: data.site?.name,
            employer: data.site,
            googlePlacesId: data.site?.place_id
          };
          if (data.siteId) params.siteId = data.siteId;
          if (data.unit) params.address2 = data.unit;
          await root.actions.startLoading();
          try {
            await root.actions.user.saveUser(params);
            root.$router.go(-1)
          } catch (e) {
            console.log(e)
          } finally {
            await root.actions.finishLoading();
          }
        }
      };

      const searchGooglePlaces = async (val) => {
        try {
          await root.actions.google.searchGooglePlaces({keyword: val});
          data.isOpen = true;
        } catch (e) {
          console.log(e)
        }
      };

      const onClickAddress = (index) => {
        data.site = json(root.state.google.placeResults[index]);
        data.address = data.site.formatted_address;
        setTimeout(() => {
          data.isOpen = false;
        }, 1000)
      };
      return {
        onClickAddress,
        onPressContinue,
        searchGooglePlaces,
        ...toRefs(data)
      }
    },
    watch: {
      address: async function (val) {
        if (typeof val === "object") return;
        if (val) await this.searchGooglePlaces(val);
      },
      selectedAddress: function (val) {
        console.log(val, 'selectedAddress');
        if (val) {
          this.recipient = val?.name;
          this.number = get(val, 'phones[0].number', null);
          this.unit = val?.address2;
          this.site = val;
          this.address = val?.address;
          this.siteId = val?.id;
          setTimeout(() => this.isOpen = false, 1000)
        } else {
          this.siteId = null;
        }
      }
    },
    async mounted() {
      if (this.$route.query.id) {
        const val = json(this.state.currentUser.sites.find(s => s.id === this.$route.query.id));
        this.recipient = val?.name;
        this.number = get(val, 'phones[0].number', null);
        this.unit = val?.address2;
        this.site = val;
        this.address = val?.address;
        this.siteId = val?.id;
        setTimeout(() => this.isOpen = false, 1000)
      } else {
        this.site = await JSON.parse(localStorage.getItem('famous_jerk_offerAddress'));
        if (this.site) {
          this.address = this.site?.formatted_address;
        } else {
          this.site = json(this.state.currentUser.site);
          this.address = this.site?.address;
        }
        this.number = get(this, 'state.currentUser.site.phones[0].number');
        this.recipient = get(this, 'state.currentUser.firstName') + ' ' + get(this, 'state.currentUser.lastName');
        setTimeout(() => {
          this.isOpen = false;
        }, 1000)
      }
    }
  }
</script>

<style lang="css" scoped>
  .address-item {
    display: flex;
    align-items: center;
    padding: 15px 18px;
    border-bottom-width: 1px !important;
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom-color: #B4B4B4 !important;
    border-style: solid !important;
    font-weight: 300;
    font-size: 12px;
    text-transform: uppercase;
  }

  .address-view {
    width: 100%;
    border: 1px solid #B4B4B4;
    box-sizing: border-box;
    max-height: 220px;
    overflow-y: scroll;
  }

  select {
    border: 0;
    outline: 0;
  }

  .field-name {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
  }

  .field-area {
    display: flex;
    border-bottom-width: 1px !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-top-width: 0 !important;
    border-style: solid !important;
    border-color: black !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 8px;
    padding-left: 2px;
    padding-right: 2px;
  }

  input.field-area:focus {
    outline-width: 0 !important;
  }
</style>
